import React from 'react';

function App() {
  return (
    <div style={{with: '90%', height: '90%'}}>
     <iframe loading="lazy" title="catalago" style={{position: 'absolute', width: '90%', height: '90%', margin: 'auto', top: 0, left: '0', right: '0',bottom: '0'}} src="https://www.canva.com/design/DAFwIl6ZO3A/view?embed" allowFullScreen="allowfullscreen" allow="fullscreen">
        </iframe>
    </div>
  );
}

export default App;
